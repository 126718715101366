import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"

import { PROJECTS_DATA } from "../../projects/PROJECTS_DATA"
import ProjectsThumbnail from "../projectsPage/ProjectsThumbnail.component"

const parentVariant = {
  animate: {
    transition: {
      delayChildren: 0.35,
      staggerChildren: 0.08,
    },
  },
}

const childVariant = {
  initial: {
    opacity: 0,
    y: "5%",
  },
  animate: {
    opacity: 1,
    y: "0%",
    transition: {
      duration: 1,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
}

const ProjectsContainer = ({ min = 0, max = PROJECTS_DATA.length }) => {
  const data = useStaticQuery(graphql`
    {
      heroImage: allFile(
        filter: {
          sourceInstanceName: { eq: "projects" }
          relativeDirectory: { eq: "hero-image" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 730) {
                originalName
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const [ref, inView] = useInView({ triggerOnce: true })

  return (
    <motion.section
      ref={ref}
      variants={parentVariant}
      initial="initial"
      animate={inView ? "animate" : "initial"}
      className="projects-container"
    >
      {PROJECTS_DATA.slice(min, max).map(
        ({ id, query, url, name, developer, address, location }) => (
          <Fragment key={id}>
            <ProjectsThumbnail
              childVariant={childVariant}
              data={data.heroImage}
              query={query}
              url={url}
              name={name}
              developer={developer}
              address={address}
              location={location}
            />
          </Fragment>
        )
      )}
    </motion.section>
  )
}

export default ProjectsContainer
